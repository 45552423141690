import { Field, Form, FormikErrors, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import Recaptcha from 'react-recaptcha';
import { IContactFormExtendedProps } from './contact-form';
import { createHelpDeskFormEntityAsync } from '../../actions/DataActionExtension.g';
import { IActionContext } from '@msdyn365-commerce/core-internal';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { IContactFormConfig, IContactFormResources } from './contact-form.props.autogenerated';
interface IFormValues {
    firstname: string;
    email: string;
    lastname: string;
    phone: string;
    message: string;
    reCaptcha: string;
    reCaptchaKey: string;
    inquiryType: string;
    resources: IContactFormResources;
}

interface IContactFormProps {
    actionContext: IActionContext;
    recaptchaKey: string | undefined;
    resources: IContactFormResources;
    config: IContactFormConfig;
    handleSubmitStatusMessage(isSuccess: boolean): void;
    handleResetStatusMessage(): void;
}

// tslint:disable-next-line: max-func-body-length
const InnerForm = (props: FormikProps<IFormValues> & IContactFormProps) => {
    const {
        touched,
        errors,
        isSubmitting,
        resources: { firstnameLabel, lastnameLabel, inquiryTypeLabel, messageLabel, phoneLabel, submitLabel, emailLabel },
        config: { inquiryTypeOptions }
    } = props;
    const verifyCallback = (response: string) => {
        props.values.reCaptcha = response;
    };
    let options: string[] = [];
    if (inquiryTypeOptions) {
        // @ts-ignore -- This is a mobx array
        options = [...inquiryTypeOptions];
    }

    return (
        <Form translate='false'>
            <div className='col-sm-12 col-md-12 fg'>
                <label htmlFor='inquirytype'>{inquiryTypeLabel}</label>
                <Field component='select' id='inquirytype' name='inquirytype' multiple={false}>
                    {options.map(opt => (
                        <option value={opt?.replace(/ /g, '-') || opt} key={opt}>
                            {opt}
                        </option>
                    ))}
                </Field>
            </div>

            <div className='col-sm-12 col-md-6 fg'>
                <label htmlFor='firstname'>{firstnameLabel}</label>
                <Field id='firstname' name='firstname' />

                {touched.firstname && errors.firstname && <div className='error'>{errors.firstname}</div>}
            </div>

            <div className='col-sm-12 col-md-6 fg'>
                <label htmlFor='lastname' className='required'>
                    {lastnameLabel}
                </label>
                <Field id='lastname' name='lastname' />
                {touched.lastname && errors.lastname && <div className='error'>{errors.lastname}</div>}
            </div>
            <div className='col-sm-12 col-md-6 fg'>
                <label htmlFor='email' className='required'>
                    {emailLabel}
                </label>
                <Field id='email' name='email' type='email' />
                {touched.email && errors.email && <div className='error'>{errors.email}</div>}
            </div>

            <div className='col-sm-12 col-md-6 fg'>
                <label htmlFor='phone'>{phoneLabel}</label>
                <Field id='phone' name='phone' type='tel' />
            </div>

            <div className='col-sm-12 fg'>
                <label htmlFor='message'>{messageLabel}</label>
                <Field component='textarea' id='message' name='message' />
            </div>
            {props.values.reCaptchaKey !== '' && (
                <div className='col-sm-12 fg'>
                    <br />

                    <Recaptcha sitekey={props.values.reCaptchaKey} render='explicit' theme='light' verifyCallback={verifyCallback} />
                    {touched.reCaptcha && errors.reCaptcha && <div className='error'>{errors.reCaptcha}</div>}
                </div>
            )}
            <div className='col-sm-12 fg'>
                <button className='msc-cta__primary' type='submit' disabled={isSubmitting}>
                    {submitLabel}
                </button>
            </div>
        </Form>
    );
};

const ContactForm = withFormik<IContactFormProps, IFormValues>({
    mapPropsToValues: props => {
        return {
            firstname: '',
            email: '',
            lastname: '',
            phone: '',
            message: '',
            reCaptcha: '',
            reCaptchaKey: '',
            inquiryType: '',
            resources: props.resources
        };
    },
    validate: (values: IFormValues, props: IContactFormProps) => {
        const emailtest = /\S+@\S+\.\S+/;
        const {
            config: { useRecaptcha }
        } = props;
        const errors: FormikErrors<IFormValues> = {};

        if (useRecaptcha && values.reCaptchaKey !== '' && !values.reCaptcha) {
            errors.reCaptcha = props.resources.requiredMessage;
        }

        if (!values.email) {
            errors.email = props.resources.requiredMessage;
        } else if (!emailtest.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.firstname) {
            errors.firstname = props.resources.requiredMessage;
        }

        if (!values.lastname) {
            errors.lastname = props.resources.requiredMessage;
        }

        if (!values.message) {
            errors.message = props.resources.requiredMessage;
        }

        return errors;
    },

    handleSubmit: async (values, formikbag) => {
        await createHelpDeskFormEntityAsync(
            { callerContext: formikbag.props.actionContext },
            values.firstname,
            values.lastname,
            values.email,
            values.phone,
            values.inquiryType,
            values.message
        );
        formikbag.resetForm();
        formikbag.props.handleSubmitStatusMessage(true);
        formikbag.setSubmitting(false);
        setTimeout(() => {
            formikbag.props.handleResetStatusMessage();
        }, 1000 * 15);
    }
})(InnerForm);

const ContactFormView: React.FC<IContactFormExtendedProps> = props => {
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [isFailure, setIsFailure] = React.useState<boolean>(false);
    const handleSubmitStatusMessage = (isSuccess: boolean) => {
        if (isSuccess) {
            setIsSuccess(true);
            setIsFailure(false);
        } else {
            setIsFailure(true);
            setIsSuccess(false);
        }
    };
    const handleResetStatusMessage = () => {
        setIsSuccess(false);
        setIsFailure(false);
    };
    const { textBox } = props;
    return (
        <>
            <div className='contact-form'>
                <h1>{props.resources.contactTitle}</h1>
                <br />
                <Node className='contact-form-richtext'>{textBox}</Node>
                {isSuccess ? <div className={'msc-alert msc-alert-success'}>{props.config.successMessage}</div> : null}
                {isFailure ? <div className='msc-alert msc-alert-danger'>There was an error. Please try again.</div> : null}
                <ContactForm
                    actionContext={props.context.actionContext}
                    recaptchaKey={props.config.captchaKey}
                    handleResetStatusMessage={handleResetStatusMessage}
                    handleSubmitStatusMessage={handleSubmitStatusMessage}
                    resources={props.resources}
                    config={props.config}
                />
            </div>
        </>
    );
};

export default ContactFormView;
