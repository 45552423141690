import { Field, Form, FormikErrors, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import Recaptcha from 'react-recaptcha';
import { ILoyaltyUserFormExtendedProps } from './add-user-by-loyalty-card-form';
import { addUserByLoyaltyCardAsync } from '../../actions/DataActionExtension.g';
import { IActionContext } from '@msdyn365-commerce/core-internal';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { IAddUserByLoyaltyCardFormConfig, IAddUserByLoyaltyCardFormResources } from './add-user-by-loyalty-card-form.props.autogenerated';
interface IFormValues {
    loyaltyCardId: string;
    zipCode: string;
    email: string;
    reCaptcha: string;
    reCaptchaKey: string;
    resources: IAddUserByLoyaltyCardFormResources;
}

interface ILoyaltyCardFormProps {
    actionContext: IActionContext;
    recaptchaKey: string | undefined;
    resources: IAddUserByLoyaltyCardFormResources;
    config: IAddUserByLoyaltyCardFormConfig;
    handleSubmitStatusMessage(isSuccess: boolean): void;
    handleResetStatusMessage(): void;
}

// tslint:disable-next-line: max-func-body-length
const InnerForm = (props: FormikProps<IFormValues> & ILoyaltyCardFormProps) => {
    const {
        touched,
        errors,
        isSubmitting,
        resources: { loyaltyCardIdLabel, zipCodeLabel, emailLabel, submitLabel },
        config: {}
    } = props;
    const verifyCallback = (response: string) => {
        props.values.reCaptcha = response;
    };

    return (
        <Form translate='false'>
            <div className='col-sm-12 col-md-12 fg'>
                <label htmlFor='loyaltyCardId'>{loyaltyCardIdLabel}</label>
                <Field id='loyaltyCardId' name='loyaltyCardId' />

                {touched.loyaltyCardId && errors.loyaltyCardId && <div className='error'>{errors.loyaltyCardId}</div>}
            </div>

            <div className='col-sm-12 col-md-8 fg'>
                <label htmlFor='email' className='required'>
                    {emailLabel}
                </label>
                <Field id='email' name='email' type='email' />
                {touched.email && errors.email && <div className='error'>{errors.email}</div>}
            </div>

            <div className='col-sm-12 col-md-4 fg'>
                <label htmlFor='zipCode' className='required'>
                    {zipCodeLabel}
                </label>
                <Field id='zipCode' name='zipCode' />
                {touched.zipCode && errors.zipCode && <div className='error'>{errors.zipCode}</div>}
            </div>

            {props.values.reCaptchaKey !== '' && (
                <div className='col-sm-12 fg'>
                    <br />

                    <Recaptcha sitekey={props.values.reCaptchaKey} render='explicit' theme='light' verifyCallback={verifyCallback} />
                    {touched.reCaptcha && errors.reCaptcha && <div className='error'>{errors.reCaptcha}</div>}
                </div>
            )}
            <div className='col-sm-12 fg'>
                <button className='msc-cta__primary' type='submit' disabled={isSubmitting}>
                    {submitLabel}
                </button>
            </div>
        </Form>
    );
};
// let successMsg = '';
const LoyaltyCardForm = withFormik<ILoyaltyCardFormProps, IFormValues>({
    mapPropsToValues: props => {
        return {
            loyaltyCardId: '',
            email: '',
            zipCode: '',
            reCaptcha: '',
            reCaptchaKey: '',
            resources: props.resources
        };
    },
    validate: (values: IFormValues, props: ILoyaltyCardFormProps) => {
        const emailtest = /\S+@\S+\.\S+/;
        const {
            config: { useRecaptcha }
        } = props;
        const errors: FormikErrors<IFormValues> = {};

        if (useRecaptcha && values.reCaptchaKey !== '' && !values.reCaptcha) {
            errors.reCaptcha = props.resources.requiredMessage;
        }

        if (!values.loyaltyCardId) {
            errors.loyaltyCardId = props.resources.requiredMessage;
        }

        if (!values.zipCode) {
            errors.zipCode = props.resources.requiredMessage;
        }

        if (!values.email) {
            errors.email = props.resources.requiredMessage;
        } else if (!emailtest.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    },

    handleSubmit: async (values, formikbag) => {
        try {
            const res = await addUserByLoyaltyCardAsync(
                { callerContext: formikbag.props.actionContext },
                values.loyaltyCardId,
                values.zipCode,
                values.email
            );
            if (res.Success) {
                // successMsg = res.Message ?? '';
                formikbag.resetForm();
            }
            formikbag.props.handleSubmitStatusMessage(res.Success);
            formikbag.setSubmitting(false);
        } catch (e) {
            formikbag.props.handleSubmitStatusMessage(false);
            formikbag.setSubmitting(false);
        }
    }
})(InnerForm);

const LoyaltyCardFormView: React.FC<ILoyaltyUserFormExtendedProps> = props => {
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [isFailure, setIsFailure] = React.useState<boolean>(false);
    const handleSubmitStatusMessage = (isSuccess: boolean) => {
        if (isSuccess) {
            setIsSuccess(true);
            setIsFailure(false);
        } else {
            setIsFailure(true);
            setIsSuccess(false);
        }
    };
    const handleResetStatusMessage = () => {
        setIsSuccess(false);
        setIsFailure(false);
    };
    const { textBox } = props;

    return (
        <>
            <div className='contact-form'>
                <h1>{props.config.title}</h1>
                <br />
                <Node className='contact-form-richtext'>{textBox}</Node>
                {isSuccess ? <div className={'msc-alert msc-alert-success'}>{props.resources.loyaltyRegistrationSuccess}</div> : null}
                {isFailure ? <div className='msc-alert msc-alert-danger'>{props.resources.errorMessage}</div> : null}
                <LoyaltyCardForm
                    actionContext={props.context.actionContext}
                    recaptchaKey={props.config.captchaKey}
                    handleResetStatusMessage={handleResetStatusMessage}
                    handleSubmitStatusMessage={handleSubmitStatusMessage}
                    resources={props.resources}
                    config={props.config}
                />
            </div>
        </>
    );
};

export default LoyaltyCardFormView;
